import { authData, getSetFn } from '@/utils/MKGetSet';
import axios from 'axios';
import store from '@/store/index';

import * as T from '@/types/MKAxiosParams';
import { changeDateFormat } from '@/utils/MKCommon';
// import { setInterceptors } from './common/interceptors';
// 단순 get요청으로 인증값이 필요없는 경우
export const axiosApi = (url: any) => {
  const instance = axios.create({ baseURL: url })
  return instance
}

// // post, delete등 api요청 시 인증값이 필요한 경우
// export const axiosAuthApi = (url: any) => {
//   // const token = '토큰 값'
//   const instance = axios.create({
//     baseURL: url
//   })
//   return setInterceptors(instance);

// }

function showAxiosLoading(showYn: boolean) {
  const loadingCompo = document.getElementById('axiosShadow');
  if (showYn) {
    /*  if (loadingCompo.style.display === 'flex') */
    if (loadingCompo) loadingCompo.style.display = 'flex';
    // setTimeout(() => {
    //   if (loadingCompo) loadingCompo.style.display = 'none'
    // }, 3000)
  } else {
    if (loadingCompo) loadingCompo.style.display = 'none';
  }
}
function changeText (text : any) {
  if (text) {
    let changeTxt = ''
    const indexOf = text.indexOf('KO$^$')
    if (indexOf === -1) {
      return text
    } else {
      changeTxt = makeMtextMap(text, 'KO')
      if (changeTxt) { return changeTxt }
    }
  }
}
function makeMtextMap (str : any, leng : any) {
// string if
  const returnMap = new Map()
  if (str) {
    const splitMtext = str.split('$#$')
    // split if ~> $$가 없다면?
    for (let i = 0; i < splitMtext.length; i++) {
      const splitMtextDetail = splitMtext[i].split('$^$')
      // split if ~> $$가 없다면?
      returnMap.set(splitMtextDetail[0], splitMtextDetail[1])
    }
  }
  return returnMap.get(leng)
}

const HEADERS = {
  'Content-type': 'application/json; text/html; charset=utf-8;',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,POST,PATCH,PUT,DELETE,OPTIONS',
  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  'Access-Control-Allow-Credentials': true,
};
// const basedUrl = 'https://mankik.com/sMankik';
const basedUrl = '';
export const HTTPfunctions = {
  // etc.
  
  loginCheck: async (): Promise<any> => {
    try {
      const param =  {
        tcAccessToken: 'test0',
        userEmail: 'mankik@gmail.com'
      }
      const res = await axios.post(`${basedUrl}/mk.loginCheck`, param, {
        headers: HEADERS,
      });
      if (res.data.result) {
        console.log(res.data)
        authData.setUserInfo(res.data.user)
        authData.setUserAll(res.data)
        //this.$store.dispatch('MK_USER/AC_USER', userResult.data.user);
      } else {
        // localStorage.removeItem('user');
      }
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  saveUser: async (param: object | any): Promise<any> => {
    try {
      if (localStorage.getItem('fcmToken')) {
        param.fcmKey = localStorage.getItem('fcmToken')
      }
      const res = await axios.post(`${basedUrl}/mk.saveUser`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  sendPushMsg: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.saveContentsAndSendPush`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
	//내 상세
	getUserDetail: async (param: object | any): Promise<any> => {
    try {
      let todayDate = '' as any
      const date = new Date()
      const year = date.getFullYear()
      let month = date.getMonth() + 1 + ''
      let day = date.getDate() + 1 + ''
      if (month.length === 1) {
        month = '0' + month
      }
      if (day.length === 1) {
        day = '0' + day
      }
      todayDate = year + '-' + month + '-' + day
      param.searchDate = todayDate
      const res = await axios.post(`${basedUrl}/mk.getUserDetail`, param, {
        headers: HEADERS,
      });
      if (res.data.result) {
        await authData.replaceFollowingAppList(res.data.appList)   
        await authData.setUserPoint(res.data.pointList) 
        await authData.setUserAll(res.data) 
        

      }
      return res;
    } catch (error) {
      console.log(error);
    }
	},
  // 앱
  getAppList: async (param: T.GetAppListParam): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.getAppList`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  saveApp: async (param: T.SaveAppParam): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.saveApp`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  deleteApp: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.deleteApp`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  // 가족
  getCabinetList: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.getCabinetList`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  // 내 가족
  getCabinetListByUser: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.getCabinetListByUser`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  
  // 가족
  getUserList: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.getUserList`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  saveCabinet: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.saveCabinet`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  deleteCabinet: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.deleteCabinet`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  // 컨텐츠
  saveContents: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.saveContents`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  saveContentsAndSendPush: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(
        `${basedUrl}/mk.saveContentsAndSendPush`,
        param,
        {
          headers: HEADERS,
        }
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  getContentsList: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.getContentsList`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  deleteContents: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.deleteContents`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  getMCabUserList: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.getMCabUserList`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  saveMCabUser: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.saveMCabUser`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  deleteMCabUser: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.deleteMCabUser`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  // 구독
  saveFollower: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.saveFollower`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  deleteFollower: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.deleteFollower`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  getFollowerList: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.getFollowerList`, param, {
        headers: HEADERS,
      });
      if (res.data) {
        await authData.replaceFollowingAppList(
          res.data.follower
        );  
      }
      
      return res;
    } catch (error) {
      console.log(error);
    }
  },
  //이력
  getUserDoList: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.getUserLogList`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
	saveUserLog: async (param: object): Promise<any> => {
    try {
      const res = await axios.post(`${basedUrl}/mk.saveUserLog`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
	// 포인트
	getPointList: async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.getPointList`, param, {
        headers: HEADERS,
      });
      return res;

    } catch (error) {
      console.log(error);
    }
	},
	savePoint:  async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.savePoint`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
	//뱃지
		getBadgeList: async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.getBadgeList`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
	saveBadge:  async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.saveBadge`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
	//챌린지
	getChallengeList: async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.getChallengeList`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
	saveChallenge:  async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.saveChallenge`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
  // 오늘의 일
  getTodoList:  async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.getTodoList`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
  saveTodo:  async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.saveTodo`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
  getSelectTodoDetail:  async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.getSelectTodoDetail`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
  deleteTodo: async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.deleteTodo`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
  getTodoListGroupCab: async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.getTodoListGroupCab`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
  // 집
  saveHouse: async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.saveHouse`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
  getHouseList: async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.getHouseList`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
  deleteUser: async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.deleteUser`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
  saveMContents: async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.saveMContents`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
  deleteMContents: async (param: object): Promise<any> => {
		try {
      const res = await axios.post(`${basedUrl}/mk.deleteMContents`, param, {
        headers: HEADERS,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
	},
  
};

interface HTTPfn {
  loginCheck(): Promise<any>;
  saveUser(param: object): Promise<any>;
  sendPushMsg(param: object): Promise<any>;
  getAppList(param: object): Promise<any>;
  saveApp(param: object): Promise<any>;
  deleteApp(param: object): Promise<any>;
  getCabinetList(param: object): Promise<any>;
  getCabinetListByUser(param: object): Promise<any>;
  saveCabinet(param: object): Promise<any>;
  deleteCabinet(param: object): Promise<any>;
  getMCabUserList(param: object): Promise<any>;
  saveMCabUser(param: object): Promise<any>;
  deleteMCabUser(param: object): Promise<any>;
  saveContents(param: object): Promise<any>;
  saveContentsAndSendPush(param: object): Promise<any>;
  getContentsList(param: object): Promise<any>;
  deleteContents(param: object): Promise<any>;
  saveFollower(param: object): Promise<any>;
  deleteFollower(param: object): Promise<any>;
  getFollowerList(param: object): Promise<any>;
  getUserDoList(param: object): Promise<any>;
	getUserList(param: object): Promise<any>;
	saveUserLog(param: object): Promise<any>;
	getPointList(param: object): Promise<any>;
	savePoint(param: object): Promise<any>;
	getBadgeList(param: object): Promise<any>;
	saveBadge(param: object): Promise<any>;
	getChallengeList(param: object): Promise<any>;
	saveChallenge(param: object): Promise<any>;
  getTodoList(param: object): Promise<any>;
  saveTodo(param: object): Promise<any>;
  getSelectTodoDetail(param: object): Promise<any>;
  saveHouse(param: object): Promise<any>;
  getHouseList(param: object): Promise<any>;
  deleteTodo(param: object): Promise<any>;
  getTodoListGroupCab(param: object): Promise<any>;
  deleteUser(param: object): Promise<any>;
  saveMContents(param: object): Promise<any>;
  deleteMContents(param: object): Promise<any>;
}

interface FnSetPopPosition {
  (w: number, h: number): string;
}

interface InputTypes {
  [key: string]: string;
}


export default {
  install(Vue: any) {
    Vue.config.globalProperties.$gHTTPfunctions = HTTPfunctions;
    Vue.config.globalProperties.$gShowAxiosLoading = showAxiosLoading;
    Vue.config.globalProperties.$gChangeKoText = changeText;
  },
};
