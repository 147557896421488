const api = {
  getUserPhoneContact: new Map(
    [
        ['API_CODE', 'MIF_M000'],
        ['API_NAME', '저장된 연락처 가져오기'],
        ['API_URL', 'webviewBridge.getUserContacts'],
        ['API_DESC', '사용자 휴대폰에 저장된 연락처 리스트를 가져오는 함수<br>webviewBridge.registerCallbackFunc를통해 초기셋팅 한 리스너 함수를 통해 결과가 리턴됩니다.<br>해당함수는 하이브리드 앱 위에서만 정상동작합니다.<br>보안상 승인된 도메인검증을 시행합니다.'],
        [
            'mParameterList', ''
        ],
        [
          'mSampleCode', 
                    `
        getUserContacts () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-undef
          webviewBridge.getUserContacts()
        },
        `
                ]
            ]
    ),
    getUserLocationAndCount: new Map(
      [
          ['API_CODE', 'MIF_M001'],
          ['API_NAME', '걸음수 및 위치정보 가져오기'],
          ['API_DESC', '만끽 통합 맵을 원하는 위치에 추가하고<br>api 호출시점부터의 유저 걸음수, 현재위치를 실시간으로 맵에 그려주는 함수<br>해당함수는 하이브리드 앱 위에서만 정상동작합니다.<br>보안상 승인된 도메인검증을 시행합니다.'],
          [
              'mParameterList', ''
          ],
          [
              'mReturnList', ''
          ],
          [
            'mSampleCode', 
                      `
                      
          //1. html추가(*id: map)
          <div id="map" style="width: 100%; height: 100%;"></div>

          //2. 마운트시점에 맵을 화면에 그리는 함수 추가
          addLoginFrame () {
            const data = {
              size: {
                w: '100%',
                h: '100%',
              },
            }
            iframeService.drawingMap(data, this.GPSRecvMsg)  //GPSRecvMsg는 콜백함수
          },

          //3. 걸음수 카운트, 실시간 위치정보 수신 시작
          iframeService.startCount() 

          //4. 콜백함수를 통해 메세지 수신등록, 수신 후 맵에 그림을 그려주는 함수 호출
          GPSRecvMsg (data: any) {
            console.log(data)
            if (!data || !data.location) return 
            // sampleList: {lat:number, lng:number}[]
            this.sampleList.push(data.location)
            tcSso.updateMap({jogRoute: this.sampleList, isJogging: true, zoom: 21}) //zoom은 맵의 확대정도
          }
          
          //5. 걸음수 카운트, 실시간 위치정보 수신 종료
          iframeService.endCount() 
          tcSso.updateMap({jogRoute: this.sampleList, isJogging: false})
          ,
          `
                  ]
              ]
      ),
    sendPushBarAlim: new Map(
        [
            ['API_CODE', 'MIF_M002'],
            ['API_NAME', '알림 보내기'],
            ['API_URL', 'mkFunctions.sendAlim'],
            ['API_DESC', '사용자 휴대폰에 푸쉬알림을 전송할 수 있는 함수<br>요청하는 앱token, 요청하는 사용자token로 요청하여 알림을 보냅니다.<br>보안상 승인된 도메인검증을 시행합니다.<br>actorList의 MKContentsActor는 {accessKind: String (A(앱)/C(가족)/U(유저)), accessKey: Integer, accessName: String}으로 구성되어있습니다.'],
            [
                'mParameterList', JSON.stringify([
                    /* { key:'TcAccessToken' , type:'String', valiYn:1, description: '유저 AccessToken', value:'유저별 할당 고정'},
                    { key:'appAccessToken' , type:'String', valiYn:1, description: '미니앱 AccessToken', value:'미니앱별 할당 고정'}, */
                    { key:'jobkindId' , type:'String', valiYn:1, description: '알림종류', value:'CONT'},
                    { key:'title' , type:'String', valiYn:1, description: '알림 제목', value:'보내고자 하는 알림의 제목'},
                    { key:'bodyFullStr' , type:'String', valiYn:1, description: '알림 내용', value:'알림의 세부내용'},
                    { key:'bodyMinStr' , type:'String', valiYn:1, description: '알림 내용', value:'알림의 세부내용(짧게)'},
                    { key:'creUserName' , type:'String', valiYn:1, description: '발송하는 유저 이름', value:''},
                    { key:'creUserKey' , type:'Integer', valiYn:1, description: '발송한 유저키', value:'유저별 할당 고정'},
                    { key:'creAppKey' , type:'Integer', valiYn:0, description: '미니앱에서 발송된경우, 발송 앱', value:'미니앱별 할당 고정'},
                    { key:'attachFileList' , type:'List<MKFile>', valiYn:0, description: '첨부된 파일리스트', value:'본문내용에 포함된 파일 혹은 첨부파일'},
                    { key:'actorList' , type:'List<MKContentsActor>', valiYn:1, description: '수신대상 리스트', value:'알림을 수신받을 대상(앱/가족/유저)'}
                  ])
            ],
            [
                'mReturnList', JSON.stringify([
                    { key:'result' , type:'Boolean', valiYn:1, description: '성공, 실패 여부', value:'true/false'},
                    { key:'resultMsg' , type:'String', valiYn:1, description: '성공, 실패 여부', value:'"OK"/"NG"'}
                  ])
            ],
            [
							'mSampleCode', 
								`
  async sendAlim () {
    const accessObj = {} as {accessKind: string, accessKey: number}
    accessObj.accessKind = 'A'
    accessObj.accessKey = 1
    const param = {}
    param.title = '테스트 알림제목'
    param.bodyFullStr = '테스트 알림내용'
    param.actorList = [accessObj]
    param.creAppKey = 1 //만끽
    param.creUserKey = 1 //수민
    mkFunctions.sendAlim({contents: param}, this.callback)
  }
  callback(result) {
    console.log(result)
  }
  `
            ]
        ]
    ),
    saveFollower: new Map(
        [
            ['API_CODE', 'MIF_M003'],
            ['API_NAME', '미니앱 구독하기'],
            ['API_URL', 'https://[MANKIK_URI]/mk.saveFollower'],
            ['API_DESC', '만끽 내의 미니 앱을 구독하는 함수<br>요청하는 앱token, 요청하는 사용자token로 요청하여 미니앱을 구독합니다. <br>보안상 승인된 도메인검증을 시행합니다.'],
            [
                'mParameterList', JSON.stringify([
                    /* { key:'TcAccessToken' , type:'String', valiYn:1, description: '유저 AccessToken', value:'유저별 할당 고정'},
                    { key:'appAccessToken' , type:'String', valiYn:1, description: '내 미니앱 AccessToken', value:'미니앱별 할당 고정'}, */
                    { key:'targetAppKey' , type:'Integer', valiYn:1, description: '미니앱 key', value:'미니앱별 할당 고정'},
                    { key:'notiYn' , type:'Boolean', valiYn:0, description: '해당 앱 알림 여부', value:'true/false'}
                  ])
            ],
            [
                'mReturnList', JSON.stringify([
                    { key:'result' , type:'Boolean', valiYn:1, description: '성공, 실패 여부', value:'true/false'},
                    { key:'resultMsg' , type:'String', valiYn:1, description: '성공, 실패 여부', value:'"OK"/"NG"'}
                  ])
            ],
            [
                'mSampleCode', `async saveFollower () {
                    const accessObj = {} as {accessKind: string, accessKey: number}
                    accessObj.accessKind = 'A'
                    accessObj.accessKey = 1
                    const param = {}
                    param.title = '테스트 알림제목'
                    param.bodyFullStr = '테스트 알림내용'
                    param.actorList = [accessObj]
                    param.creAppKey = 1 //만끽
                    param.creUserKey = 1 //수민
                    await axios.post('https://mo.d-alim.com:7443/mk.saveFollower', {contents: param})
                }`
            ]
        ]
		),
    saveUserLog: new Map(
        [
            ['API_CODE', 'MIF_M004'],
            ['API_NAME', '이력 저장하기'],
            ['API_URL', 'mkFunctions.saveUserLog'],
            ['API_DESC', '만끽 내의 이력을 저장하는 함수<br>보안상 승인된 도메인검증을 시행합니다.'],
            [
                'mParameterList', JSON.stringify([
                    // { key:'logKey' , type:'Integer', valiYn:1, description: '이력 key', value:'유저별 할당 고정'},
                    { key:'logType ' , type:'String', valiYn:1, description: '이력 종류', value:'미니앱/행위별로 할당'},
                    { key:'targetKey' , type:'Integer', valiYn:1, description: '이력의 타겟 키', value:'userKey/cabinetKey/appKey'},
                    { key:'targetKind' , type:'String', valiYn:1, description: '이력의 타겟 종류', value:'U-유저/C-가족/A-앱'},
                    { key:'comment' , type:'String', valiYn:0, description: '부가 설명', value:'이력에 대한 상세설명'},
                    { key:'frUserKey' , type:'Integer', valiYn:1, description: '행위자 key', value:'행위를 하는사람: 추후 자동생성될예정'},
                    { key:'frUserText' , type:'String', valiYn:0, description: '행위자 이름', value:'행위를 하는사람: 추후 자동생성될예정'},
                    { key:'toUserKey' , type:'Integer', valiYn:1, description: '행위를 받는 대상 key', value:'신고당함/산책요청 받는 대상'},
                    { key:'toUserText' , type:'String', valiYn:0, description: '행위를 받는 대상 이름', value:'신고당함/산책요청 받는 대상'},
                    { key:'creCabinetKey' , type:'Integer', valiYn:0, description: '이력의 타겟 가족키', value:'집(가족)별 저장되어야 할 이력인경우(욕조, 도어락), 이력이 발생한 대상 집'}
                    /* { key:'creUserKey' , type:'Integer', valiYn:0, description: '발송한 유저키', value:'유저별 할당 고정'} */
                  ])
            ],
            [
                'mReturnList', JSON.stringify([
                    { key:'result' , type:'Boolean', valiYn:1, description: '성공, 실패 여부', value:'true/false'},
                    { key:'resultMsg' , type:'String', valiYn:1, description: '성공, 실패 여부', value:'"OK"/"NG"'}
                  ])
							],
						[
							'mSampleCode', 
  `
  async saveUserLog () {
    const param = {}
    param.logType = 'CORETEST' // 각앱별 리스트업 한 이력코드 입력
    param.targetKey = 1 //최초진입시 전달받은 appList에서 선택
    param.targetKind = 'A' 
    param.frUserKey = 1 
    param.frUserText = '끽이' 
    param.toUserKey = 3  
    param.toUserText = '만이'
    param.creCabinetKey = 1 //최초진입시 전달받은 cabList 선택
    mkFunctions.saveUserLog(param, this.callback)
  }
  callback(result) {
    console.log(result)
  }`
            ]
        ]
	),
	getUserDetail: new Map(
		[
            ['API_CODE', 'MIF_M005'],
            ['API_NAME', '유저 정보 가져오기'],
            ['API_URL', 'mkFunctions.getUserDetail'],
            ['API_DESC', '만끽 내 유저의 상세 정보를 가져오는 함수<br>보안상 승인된 도메인검증을 시행합니다.'],
            [
                'mParameterList', JSON.stringify([
                    { key:'userKey' , type:'Integer', valiYn:1, description: '유저 Key', value:'유저별 할당 고정'},
                  ])
            ],
            [
                'mReturnList', JSON.stringify([
                    { key:'result' , type:'Boolean', valiYn:1, description: '성공, 실패 여부', value:'true/false'},
										{ key: 'resultMsg', type: 'String', valiYn: 1, description: '성공, 실패 여부', value: '"OK"/"NG"' },
										{ key:'appList' , type:'Array', valiYn:1, description: '내 앱 리스트', value:'true/false'},
                    { key:'cabList' , type:'Array', valiYn:1, description: '내 가족 리스트', value:'true/false'}
                  ])
							],
						[
							'mSampleCode', 							  
`
  async getUserDetail () {
    const param = {}
    param.userkey = 1 // 만끽이
    mkFunctions.getUserDetail(param, this.callback)
  }
  callback(result) {
    console.log(result)
  }`
            ]
			]
		),
		commonApi: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '앱별 공통 API'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
addEarnMoney: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '수입 추가하기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
addSpendMoney: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '지출 추가하기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	getEarnSpend: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '수입/지출리스트 가져오기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	getVisitList: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '방문이력정보 가져오기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	getNowAtHome: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '현재 집에있는 사람 정보 가져오기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	getMyBathInfo: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '나의 목욕정보 가져오기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	getNowBath: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '현재 욕실상태 가져오기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	getPersonalHealth: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '개인별 건강상태 가져오기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	getMyTodoList: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '내 일정 리스트 가져오기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	showExtraInfo: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '일정 외 정보 표기하기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	addModiTodo: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '일정 등록/수정하기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	deleteTodo: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '일정 삭제하기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	addModiChall: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '챌린지 추가/수정하기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	deleteChall: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '챌린지 삭제하기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	getChallList: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '챌린지 리스트 가져오기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	finishChall: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '챌린지 달성하기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	exitChall: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '챌린지 취소하기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	mapViewShowInfo: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '제공된 정보 표기하기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
	changeNowHome: new Map(
					[
							['API_CODE', ''],
							['API_NAME', '현재 집 상태 변경하기'],
							['API_URL', ''],
							['API_DESC', 'API가 정의되지 않았습니다.'],
							[
									'mParameterList', JSON.stringify([
										])
							],
							[
									'mReturnList', JSON.stringify([
										])
							]
					]
	),
}
export { api }
