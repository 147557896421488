<template>
  <div style="width: 50px; height: 50px; border-radius: 100%; box-shadow: 0 0 4px 4px #00000026; background: #FFFFFF; display: flex; flex-direction: column; justify-content: center; align-items: center;">
    <img src="@/assets/icons/MKAppScrollTopIcon.svg" style="height: calc(100% - 25px);" alt="">
    <p style="height:10px; font-size: 12px ;" class="fontBold">TOP</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  
})
</script>

<style scoped>

</style>