import router from '@/router';
import { UserInfo } from '@/utils/MKGetSet';
interface mkApp {
  appNameMtext: string,
  appKey: number,
  domainPath: string,
  appIconPath: string
}
interface mkPoint {
  pointList: any[],
  totalP: number
}
const MK_USER = {
  namespaced: true,
  state: {
    userInfo: {
      userKey: '',
    } as UserInfo,
    followingAppList: [] as mkApp[],
    userPoint: {} as mkPoint,
    userTotalInfo: {} as any
  },
  getters: {
    GE_USER_ALL(state: any) {
      if (state.userTotalInfo) {
        return JSON.parse(JSON.parse(state.userTotalInfo));
      } else {
        return {}
      }
      
    },
    GE_USER(state: any) {
      if (state.userInfo && state.userInfo.userKey) {
        return state.userInfo;
      } else {
        return {}
      }
    },
    GE_USER_POINT(state: any) {
      return state.userPoint;
    },
    GE_FOLLOWING_APP_LIST(state: any) {
      return state.followingAppList;
    },
  },
  mutations: {
    MU_USER(state: any, payload: any) {
      if (!payload) return
      state.userInfo = payload/* 
      if (payload.deleteYn) {
        state.userInfo.deleteYn = payload.deleteYn;
      }
      if (payload.encPhone) {
        state.userInfo.encPhone = payload.encPhone;
      }
      if (payload.phoneLast) {
        state.userInfo.phoneLast = payload.phoneLast;
      }
      if (payload.soAccessToken) {
        state.userInfo.soAccessToken = payload.soAccessToken;
      }
      if (payload.soPicUrl) {
        state.userInfo.soPicUrl = payload.soPicUrl;
      }
      if (payload.userEmail) {
        state.userInfo.userEmail = payload.userEmail;
      }
      if (payload.soType) {
        state.userInfo.soType = payload.soType;
      }
      if (payload.userDispMtext) {
        state.userInfo.userDispMtext = payload.userDispMtext;
      }
      if (payload.userKey) {
        state.userInfo.userKey = payload.userKey;
      }
      if (payload.userNameMtext) {
        state.userInfo.userNameMtext = payload.userNameMtext;
      }
      if (payload.certiDate) {
        state.userInfo.certiDate = payload.certiDate;
      }
      if (payload.outDate) {
        state.userInfo.outDate = payload.outDate;
      }
      if (payload.fcmKey) {
        state.userInfo.fcmKey = payload.fcmKey;
      } */
    },
    MU_CHANGE_FOLLOWING_APP(state: any, payload: {deleteYn: boolean, app: mkApp}) {
      if (!payload || !payload.app) return
      const index = state.followingAppList.findIndex((app : mkApp) => app.appKey === payload.app.appKey)
      if (payload.deleteYn) {
        if (index !== -1) {
          state.followingAppList.splice(index, 1)
        }
      } else {
        if (index !== -1) {
          state.followingAppList[index] = payload.app
        } else {
          state.followingAppList.push(payload.app)
        }
      }      
    },
    MU_REPLACE_FOLLOWING_APP(state: any, payload: mkApp[]) {
      if (!payload) return 
      state.followingAppList = payload
      
    },
    MU_CLEAR_USER(state: any) {
      state.userInfo = {};
    },
    MU_USER_ALL(state: any, payload: any) {
      const jsonString = JSON.stringify(payload, (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (value === payload) {
            return JSON.stringify(value); // 순환 참조를 무시
          }
        }
        return value; // 기본 동작
      });
      
      state.userTotalInfo = jsonString
    },
    MU_USER_POINT(state: any, payload: any) {
      if (!payload) return 
      state.userPoint.pointList = payload
      let totalPoint  = 0
      for (let i = 0; i < payload.length; i++) {
        const p = payload[i]
        if (p.pointVal) {
          if (p.pointType === 'D') {
            totalPoint -= Number(p.pointVal)
          } else if (p.pointType === 'A') {
            totalPoint += Number(p.pointVal)
          }
        }
      }
      state.userPoint.totalP = totalPoint
      
      
    },
  },
  actions: {
    AC_USER({ commit }: any, payload: object) {
      commit('MU_USER', payload);
    },
    AC_CLEAR_USER({ commit }: any) {
      commit('MU_CLEAR_USER');
    },
    AC_CHANGE_FOLLOWING_APP({ commit }: any ,payload: {deleteYn: boolean, app: mkApp}) {
      commit('MU_CHANGE_FOLLOWING_APP', payload);
    },
    AC_REPLACE_FOLLOWING_APP({ commit }: any, payload: mkApp[]) {
      commit('MU_REPLACE_FOLLOWING_APP', payload);
    },
    AC_USER_POINT({ commit }: any, payload: any) {
      commit('MU_USER_POINT', payload);
    },
    AC_USER_ALL({ commit }: any, payload: any) {
      commit('MU_USER_ALL', payload);
    },
    
  },
};

export default MK_USER;
