import {HTTPfunctions} from '@/api/index' 

  const MK_CONT = {
      namespaced: true,
    state: {
        MK_CONTENTS: [] as any[],
        MK_NEW_CONT_KEY_LIST: [] as any[],
        MK_RECV_PUSH: [] as any[],
        MK_PUSH_CHECKYN: false as boolean,
    },
      getters: {
        GE_CONT_LIST (state: any) {
            return state.MK_CONTENTS
        },
        GE_NEW_CONT_KEY_LIST (state: any) {
            return state.MK_NEW_CONT_KEY_LIST
        },
        GE_PUSH_CHECKYN (state: any) {
            return state.MK_PUSH_CHECKYN
        },
      },
    mutations: {
        MU_CONT_LIST (state: any, payload: any) {
        if (!payload || payload.length === 0) return
        let contIndex = null as number | null
        for (let i = 0; i < payload.length; i++) {
            contIndex = state.MK_CONTENTS.findIndex((item: {contentsKey: number}) => item.contentsKey === payload[i].contentsKey)
            if (contIndex && contIndex === -1) {
                state.MK_CONTENTS.push(payload[i])
            } else if (contIndex && contIndex !== -1) {
                state.MK_CONTENTS[contIndex] = payload[i]
            }
            }
        },
        MU_ADD_NEW_CONT (state: any, payload: any) {
            state.MK_NEW_CONT_KEY_LIST.unshift(payload)
            if (state.MK_NEW_CONT_KEY_LIST.length > 20) {
                state.MK_NEW_CONT_KEY_LIST = state.MK_NEW_CONT_KEY_LIST.splice(0, 20)
            }
        },
        MU_CHANGE_NEW_PUSH_STATUS (state: any, payload: any) {
            state.MK_PUSH_CHECKYN = payload
        }
    },
      actions: {
        async AC_CHANGE_NEW_PUSH_STATUS ({ commit }: any, payload: object) {
            commit('MU_CHANGE_NEW_PUSH_STATUS', payload)
        },
        async AC_CONT_LIST ({ commit }: any, payload: object) {
            const result = await HTTPfunctions.getContentsList(payload)
            if (result.data && result.data.contents && result.data.contents.content) {
                const newKeyList = [] as number[]
                for (let i = 0; i < result.data.contents.content.length; i++) {
                    newKeyList.push(result.data.contents.content[i].contentsKey)
                }
                
                commit('MU_CONT_LIST', result.data.contents.content)
                result.data.contents.content = newKeyList
                return result
            }
            
        },
        async AC_GE_CONT_LIST ({ commit, state }: any, payload: any) {           
            let contIndex = null as number | null
            const returnData = [] as any[]
            debugger
            for (let i = 0; i < payload.length; i++) {
                contIndex = state.MK_CONTENTS.findIndex((item: {contentsKey: number}) => item.contentsKey === payload[i])
                if (contIndex !== -1) {
                  if (contIndex !== undefined && contIndex !== null) {
                    returnData.push(state.MK_CONTENTS[contIndex])
                  }
                    
                }
            }
            return returnData
        }
      }
  };
  
  export default MK_CONT