import { createStore } from 'vuex';

import createPersistedState from 'vuex-persistedstate';
import MK_USER from './MKUserData';
import MK_HISTORY from './MKHistory';
import MK_CONT from './MKContentsData';
const storageState = createPersistedState({
  paths: ['MK_USER', 'MK_CONT', 'MK_HISTORY'],
  storage: window.localStorage,
  /* key: 'vuexStore',
  storage: window.sessionStorage */
});
const store = createStore({
  modules: {
    // 키: 값 형태로 저장됩니다.
    MK_USER: MK_USER,
    MK_CONT: MK_CONT,
    MK_HISTORY: MK_HISTORY,
  },
	plugins: [storageState],
	state: {
		mIsShown: false,
  },
	mutations: {
		setShown(state: any, mIsShown) {
			state.mIsShown = mIsShown;
		}
  }
});

export default store;
