import { HTTPfunctions } from '@/api';
import store from '@/store';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
			{
    path: '/challcard',
    name: 'challenge card',
    // beforeEnter: (to, from, next) => {
    //   const user = store.getters['MK_USER/GE_USER'];
    //   if (!user || !user.userKey) {
    //     next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
    //   } else{
    //     next()
    //   }
    // },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/MKAppChalDetail.vue'),
	},
  {
    path: '/todo',
    name: 'todo',
    // beforeEnter: (to, from, next) => {
    //   const user = store.getters['MK_USER/GE_USER'];
    //   if (!user || !user.userKey) {
    //     next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
    //   } else{
    //     next()
    //   }
    // },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/TodoList.vue'),
	},
		{
    path: '/chall',
    name: 'challenge',
    // beforeEnter: (to, from, next) => {
    //   const user = store.getters['MK_USER/GE_USER'];
    //   if (!user || !user.userKey) {
    //     next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
    //   } else{
    //     next()
    //   }
    // },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/MKAppChalList.vue'),
	},
		{
    path: '/chat',
    name: 'chatroom',
    // beforeEnter: (to, from, next) => {
    //   const user = store.getters['MK_USER/GE_USER'];
    //   if (!user || !user.userKey) {
    //     next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
    //   } else{
    //     next()
    //   }
    // },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/MKAppChatRoom.vue'),
	},
	{
    path: '/chatlist',
    name: 'chatroom list',
    // beforeEnter: (to, from, next) => {
    //   const user = store.getters['MK_USER/GE_USER'];
    //   if (!user || !user.userKey) {
    //     next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
    //   } else{
    //     next()
    //   }
    // },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/MKAppChatRoomList.vue'),
	},
	{
    path: '/chatfrlist',
    name: 'chatr friend list',
    // beforeEnter: (to, from, next) => {
    //   const user = store.getters['MK_USER/GE_USER'];
    //   if (!user || !user.userKey) {
    //     next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
    //   } else{
    //     next()
    //   }
    // },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/MKAppChatFriendList.vue'),
	},
	{
    path: '/testpop',
    name: 'testpop list',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/components/api/pop/MKApiTestPop.vue'),
	},
	{
    path: '/push/:userKey',
    name: 'alarm list',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    props: true,
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/MKAppPushList.vue'),
	},
	{
    path: '/edit',
    name: 'profile edit',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/MKAppEditMyInfo.vue'),
	},
	{
    path: '/delid',
    name: 'delete account',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/MKAppDeleteId.vue'),
	},
	{
    path: '/sendpush',
    name: 'send alarm',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/MKCommonWritePush.vue'),
	},
  {
    path: '/login',
		name: 'login',
    component: () =>
			import(/*webpackChunkName: "about"*/ '@/views/MKLogin.vue'),
	},
  {
    path: '/policies',
		name: 'policies',
    component: () =>
			import(/*webpackChunkName: "about"*/ '@/views/app/MKAppPolicies.vue'),
	},
	{
    path: '/policyp',
		name: 'done policies',
		beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
			}
    },
    component: () =>
			import(/*webpackChunkName: "about"*/ '@/views/app/MKAppAgreedPolicyP.vue'),
	},
		{
    path: '/policyu',
		name: 'done policies',
		beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
			}
    },
    component: () =>
			import(/*webpackChunkName: "about"*/ '@/views/app/MKAppAgreedPolicyU.vue'),
	},
	{
    path: '/select',
		name: 'makeTargets',
		beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/popup/MKAppMakeTargetPop.vue'),
  },
  {
    path: '/applist',
    name: 'applist',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/MKAppList.vue'),
  },
  {
    path: '/cablist',
    name: 'cablist',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/MKAppCabList.vue'),
  },
  {
    path: '/poilist',
    name: 'pointlist',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/MKAppPointList.vue'),
  },
  {
    path: '/poishop',
    name: 'pointshop',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    component: () =>
      import(/*webpackChunkName: "about"*/ '@/views/app/MKAppPointMarket.vue'),
  },
  {
    path: '/selectt',
    name: 'selecttarget',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
			}
			if (from.path === '/cablist') {
				store.commit('setShown', true)
			} else {
				next()
			}
    },
    component: () =>
      import(
        /*webpackChunkName: "about"*/ '@/views/app/popup/MKAppSelectTargetPop.vue'
      ),
  },
  {
    path: '/badgemanage',
    name: 'badgemanage',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    component: () =>
      import(
        /*webpackChunkName: "about"*/ '@/views/app/MKAppBadgeManageList.vue'
      ),
  },
  {
    path: '/appMain',
    name: 'appMain',
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/app/MKAppMain.vue'),
    children: [
      {
        path: '/appTest',
        name: 'appTest',
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/app/MKApiText.vue'),
      },
      {
        path: '/appMain',
        name: 'main',
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/app/MKMain.vue'),
      },
      {
        path: '/mypage/:pUserKey',
        name: 'mypage',
        component: () =>
          import(/*webpackChunkName: "about"*/ '@/views/app/MKAppMyPage.vue'),
      },
      {
        path: '/userDo',
        name: 'userDoList',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/app/MKAppUserDoList.vue'
          ),
      },
      {
        path: '/irlist/:pCabinetKey/:pUserKey',
        name: 'irlist',
        props: true,
        component: () =>
          import(/*webpackChunkName: "about"*/ '@/views/app/MKAppUserDoList.vue'),
      },
      
      
      /* 챌린지 */
      {
        path: '/challenge',
        name: 'challengeList',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/app/MKAppChalList.vue'
          ),
      },
      {
        path: '/challenge/detail',
        name: 'challengeDetail',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/app/MKAppChalDetail.vue'
          ),
      },
      /* 채팅 */
      {
        path: '/chatting',
        name: 'chattingList',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/app/MKAppChatRoomList.vue'
          ),
      },
      {
        path: '/chatting/vspace',
        name: 'virtualSpace',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/app/MKAppChatVirtualSpace.vue'
          ),
      },
      {
        path: '/chatting/room',
        name: 'chattingRoom',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/app/MKAppChatRoom.vue'
          ),
      },
    ],
	},
	{
		path: '/',
		name: 'homePage',
		beforeEnter: (to, from, next) => {
			const user = store.getters['MK_USER/GE_USER'];
			if (!user || !user.userKey) {
				HTTPfunctions.loginCheck().then(() => { next() })
			} else {
				next()
			}
		},
		component: () =>
			import(/* webpackChunkName: "about" */ '@/views/homepage/MKHPMain.vue'),
		children: [
			{
				path: '/',
				name: 'main home page',
				component: () =>
					import(
            /* webpackChunkName: "about" */ '@/views/homepage/MKHPMainPage.vue'
					),
			},
			{
				path: '/seasono',
				name: 'season One',
				component: () =>
					import(
            /* webpackChunkName: "about" */ '@/views/homepage/MKHPSeasonOne.vue'
					),
			},
			{
				path: '/seasont',
				name: 'main page',
				component: () =>
					import(
            /* webpackChunkName: "about" */ '@/views/homepage/MKHPSeasonTwo.vue'
					),
			},
			// {
			// 	path: '/testing',
			// 	name: 'main page',
			// 	component: () =>
			// 		import(
      //       /* webpackChunkName: "about" */ '@/views/homepage/MKHPTesting.vue'
			// 		),
			// },
		]
	},
  {
    path: '/apiMain',
    name: 'apiMain',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/api/MKApiMain.vue'),
    children: [
      {
        path: '/apiMain',
        name: 'api sub main',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/api/MKApiRules.vue'
          ),
			},
			{
        path: '/apisetting',
        name: 'api setting',
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/api/MKApiSubMain.vue'),
      },
      {
        path: '/apiDetail',
        name: 'api detail',
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/api/MKApiDetail.vue'),
      },
      {
        path: '/iframeGuide',
        name: 'iframe popup guide page',
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/api/MKApiIframGuide.vue'),
			},
      {
        path: '/apiMiniAppGuide',
        name: 'apiMiniAppGuide',
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/api/MKApiMiniAppMainGuide.vue'),
			},
      {
        path: '/params',
        name: 'page call param',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/api/MKApiPageCallParam.vue'
          ),
			},
			{
        path: '/commonapi',
        name: 'common api',
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/api/MKApiCommon.vue'),
			},
    ],
  },
  {
    path: '/permissions',
    name: 'permissions',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/MKPermissions.vue'),
  },
  {
    path: '/writeAlim',
    name: 'writeAlim',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    component: () =>
      import(
        /*webpackChunkName: "about"*/ '@/views/app/MKCommonWritePush.vue'
      ),
  },{
    path: '/test',
   name: 'test',
   component: () =>
     import(/*webpackChunkName: "about"*/ '@/views/app/MKTest.vue'),
 },{
  path: '/testResult',
 name: 'testResult',
 component: () =>
   import(/*webpackChunkName: "about"*/ '@/views/app/MKTestResult.vue'),
},
  {
    path: '/namecard/:pUserKey',
    name: 'namecard',
    beforeEnter: (to, from, next) => {
      const user = store.getters['MK_USER/GE_USER'];
      if (!user || !user.userKey) {
        next() // next('/policies') // HTTPfunctions.loginCheck().then(()=> {next()})
      } else{
        next()
      }
    },
    props: true,
    component: () =>
      import(
        /*webpackChunkName: "about"*/ '@/components/common/MKAppUserNameCard.vue'
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  
  if (to.name === 'main'){
    store.dispatch('MK_HISTORY/AC_CLEAR_HISTORY_STACK', {})
  }  else {
    const history = store.getters['MK_HISTORY/GE_HISTORY_STACK']
    let historyLength = 0
    console.log(history)
    if (history.length) {
      historyLength = history.length
    }
    const param = {
      popYn: false,
      id: `router_${historyLength}`,
      targetKind: to.name
    }
    store.dispatch('MK_HISTORY/AC_ADD_HISTORY', param)
  }
  return next()
})
export default router;
