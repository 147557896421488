import store from '@/store';

export interface UserInfo {
  deleteYn: boolean;
  encPhone: string;
  phoneLast: string;
  soAccessToken: string;
  soType: string;
  soPicUrl: string;
  userPicUrl: string;
  userDispMtext: string;
  userKey: string;
  userNameMtext: string;
  tcAccessToken: string;
  userEmail: string;
  certiDate: string;
  creDate: string;
  outDate: string;
  fcmKey: string;
  osName: string;
  osVersion: string;
  apiLevel: string;
  deviceId: string;
  deviceModel: string;
  deviceBrand: string;
  isTablet: boolean;
  userType: string;
  userId: string;
  userQRImgPath: string;
  domainPath: string;
  pathMtext: string;
}

class AuthData {
  setUserPoint(data: any) {
    if (data) {
      store.dispatch('MK_USER/AC_USER_POINT', data);
    }
  }
  getUserPoint() {
    const point = store.getters['MK_USER/GE_USER_POINT'];
    if (point) {
      // return JSON.parse(user)
      return point;
    }
  }
  setUserInfo(data: UserInfo) {
    if (data) {
      store.dispatch('MK_USER/AC_USER', data);
      console.log(data)
      localStorage.setItem('user', JSON.stringify(data));
    }
  }
  
  setUserAll(data: any) {
    console.log(data)
    if (data) {
      store.dispatch('MK_USER/AC_USER_ALL', data);
    }
  }
  getUserAll() {
    const user = store.getters['MK_USER/GE_USER_ALL'];
    if (user) {
      // return JSON.parse(user)
      return user;
    }
  }
  setNewPushStatus(data: any) {
    console.log(data)
    if (data) {
      store.dispatch('MK_CONT/AC_CHANGE_NEW_PUSH_STATUS', data);
    }
  }
  getNewPushStatus() {
    const stat = store.getters['MK_USER/GE_PUSH_CHECKYN'];
    if (stat) {
      // return JSON.parse(user)
      return stat;
    }
  }
  
  getUserInfo() {
    const user = store.getters['MK_USER/GE_USER'];
    // const user = localStorage.getItem('user')
    /* const user = {
        deleteYn: false,
        encPhone: '01012345678',
        phoneLast: '5678',
        soAccessToken: 'test1',
        soType: 'G',
        userPicUrl: null,
        userKey: 2,
        userNameMtext: '테스트이이',
        userDispMtext: '테스트이이',
        tcAccessToken: 'test1',
        userEmail: 'testapp1@gmail.com',
        certiDate: null,
        creDate: '2023-06-01 02:00:00',
        outDate: null,
      }*/
    if (user) {
      // return JSON.parse(user)
      return user;
    }
  }
  setFollowingAppList(data: UserInfo) {
    if (data) {
      store.dispatch('MK_USER/AC_CHANGE_FOLLOWING_APP', data);
    }
  }
  replaceFollowingAppList(data: UserInfo) {
    if (data) {
      store.dispatch('MK_USER/AC_REPLACE_FOLLOWING_APP', data);
    }
  }
  getFollowingAppList() {
    const app = store.getters['MK_USER/GE_FOLLOWING_APP_LIST'];

    if (app) {
      // return JSON.parse(user)
      return app;
    }
  }
}

export const authData = new AuthData();

export interface getSetFn {
  setUserPoint(param: object): void;
  getUserPoint(): any;
  setUserInfo(param: object): void;
  getUserInfo(): UserInfo;
  setFollowingAppList(param: object): void;
  replaceFollowingAppList(param: object): void;
  getFollowingAppList(): UserInfo;
}

export default {
  install(Vue: any) {
    Vue.config.globalProperties.$gGetSetFunctions = authData;
  },
};
