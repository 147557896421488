import { UserInfo } from '@/utils/MKGetSet';

interface history {popYn: boolean, id: string, targetKind: string, targetKey: number}
const MK_HISTORY = {
  namespaced: true,
  state: {
    historyStack: [] as history[],
    lastRemoveId: null as string | null
  },
  getters: {
    GE_HISTORY_STACK(state: any) {
      return state.historyStack
    },
    
    GE_LAST_REMOVED_ID(state: any) {
      return state.lastRemoveId;
    },
  },
  mutations: {
    MU_HISTORY_STACK(state: any, payload: any) {
      if (!payload) return
      state.historyStack = payload 
    },
    MU_ADD_HISTORY(state: any, payload: any) {
      if (!payload) return
      const lastHistory = state.historyStack[state.historyStack.length -1]
      if (!lastHistory || lastHistory.id !== payload.id) {
        if (payload.popYn === false && (lastHistory && lastHistory.targetKind === payload.targetKind)) {
          return
        }
        state.historyStack.push(payload)
      }
    },
    MU_DELETE_STACK(state: any, payload: any) {
      if (!payload) return
      const hStack = state.historyStack
      const index = hStack.findIndex((stack : history) => stack.id === payload)
      if (index !== -1) {
        hStack.slice(0, index)
      } else {
        return
      }
    },
    MU_LAST_REMOVED_ID(state: any, payload: any) {
      if (!payload) return
      state.lastRemoveId = payload 
    },
    MU_CLEAR_HISTORY_STACK(state: any) {
      state.historyStack = []
    },
    
  },
  actions: {
    AC_HISTORY_STACK({ commit }: any, payload: any) {
      commit('MU_HISTORY_STACK', payload);
    },
    AC_LAST_REMOVED_ID({ commit }: any ,payload: any) {
      commit('MU_LAST_REMOVED_ID');
    },
    AC_ADD_HISTORY({ commit }: any ,payload: any) {
      commit('MU_ADD_HISTORY', payload);
    },
    AC_DELETE_STACK({ commit }: any ,payload: any) {
      commit('MU_DELETE_STACK', payload);
    },
    AC_CLEAR_HISTORY_STACK({ commit }: any ,payload: any) {
      commit('MU_CLEAR_HISTORY_STACK', payload);
    },
    
  },
};

export default MK_HISTORY;
