<template>
    <div style="height:100%; width: 100%; display: flex; overflow: hidden auto; align-items: center; justify-content: center; background: #FFFFFF;position: relative;" >
        <div :class="{overflowY: $route.path === '/' || $route.path === '/seasono' || $route.path === '/seasont' || $route.path === '/testing'}" class="MKWindowWidth h100P">
            <router-view />
            <div id="mk-chat-bot" type="quickBar" orgKey="1001" class="cursorP" style=""></div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
/* if (window.ReactNativeWebView) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-undef
    webviewBridge.onMessage('reqUserInfo', callbackUserInfo, null)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-undef
    webviewBridge.onMessage('CheckUserPermission')
} */

function callbackUserInfo (data:any) {
    console.log(data)
}
export default defineComponent({
    methods: {

    }
})
</script>
<style>
	.overflowY{
		overflow:hidden auto;
	}
@import url(@/css/MKCommon.css);
@import url(@/css/MKResetStyles.css);
@import 'https://mo.d-alim.com:7443/assets/TCMankik.css'

</style>
