<template>
  <div
    v-if="pBtnInfo.imgPath && !pBtnInfo.isCircle"
    @click="pBtnInfo.onClickFn"
    class="btnStyle commonSmallText fontBold"
    :class="{
      MKDeepPointBG: pBtnInfo.pointYn && pBtnInfo.deepYn,
      MKPointBG: pBtnInfo.pointYn && !pBtnInfo.deepYn,
			MKGrayBG: pBtnInfo.grayYn,
			MKPopGrayBG: pBtnInfo.popGrayYn,
      noBorderBtn: pBtnInfo.noBorder,
      imgBox: pBtnInfo.imgPath,
    }"
  >
    <img :src="pBtnInfo.imgPath" :alt="pBtnInfo.title" />
  </div>
  <div
    v-else-if="pBtnInfo.isCircle"
    @click="pBtnInfo.onClickFn"
    class="btnStyle commonSmallText fontBold"
    :class="{
      MKDeepPointBG: pBtnInfo.pointYn && pBtnInfo.deepYn,
      MKPointBG: pBtnInfo.pointYn && !pBtnInfo.deepYn,
			MKGrayBG: pBtnInfo.grayYn,
			MKPopGrayBG: pBtnInfo.popGrayYn,
      noBorderBtn: pBtnInfo.noBorder,
      circleBtn: pBtnInfo.isCircle,
    }"
  >
    <img
      v-if="pBtnInfo.imgPath"
      :src="pBtnInfo.imgPath"
      :alt="pBtnInfo.title"
    />
    <p v-else>{{ pBtnInfo.btnText }}</p>
  </div>
  <div
    v-else
    @click="pBtnInfo.onClickFn"
    class="btnStyle normalBtn commonSmallText fontBold"
    :class="{
      MKDeepPointBG: pBtnInfo.pointYn && pBtnInfo.deepYn,
      MKPointBG: pBtnInfo.pointYn && !pBtnInfo.deepYn,
			MKGrayBG: pBtnInfo.grayYn,
			MKPopGrayBG: pBtnInfo.popGrayYn,
      noBorderBtn: pBtnInfo.noBorder,
      lowHeightBtn: pBtnInfo.noHighYn,
    }"
  >
    {{ pBtnInfo.btnText }}
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

/**
 * pBtnInfo 정보
 * {
 *   btnText: string,
 *   onClickFn: Function,
 *   pointYn?: boolean,
 *   noBorder?: boolean,
 *   imgPath?: string,
 *   isCircle?: boolean,
 * }
 */

export default defineComponent({
  props: {
    pBtnInfo: Object as any,
    pPointYn: Boolean,
  },
  data() {
    return {};
  },
});
</script>
<style scoped>
.btnStyle {
  min-width: 60px;
  /* max-width: 80%; */
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  border: 1px solid #aaa;
  background-color: #ffffff;
  padding: 0 8px;
  cursor: pointer;
}
.normalBtn {
  min-height: 40px;
}
.imgBox {
  max-width: 100px;
  height: 100px;
}
.imgBox img {
  width: 100%;
}
.circleBtn {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
.circleBtn img {
  width: 100%;
}
.noBorderBtn {
  border: none;
}
.lowHeightBtn {
  min-height: 30px!important;
}
</style>
