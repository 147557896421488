const MKConst = {
  VALUE_TAG_TYPE_IMAGE: 'G',
  VALUE_TAG_TYPE_INPUT_TEXT: 'T',
  VALUE_TAG_TYPE_TEXTAREA: 'TA',
  VALUE_TAG_TYPE_INPUT_NUMBER: 'N',
  VALUE_TAG_TYPE_SELECT: 'S',
  VALUE_TAG_TYPE_SELECT_BUTTON: 'SB',
  VALUE_TAG_TYPE_PASSWORD: 'P',

  VALI_CHECK_TYPE_NOT_NULL: 'NULL',
  VALI_CHECK_TYPE_MAX_SIZE: 'MAX',
  VALI_CHECK_TYPE_MIN_SIZE: 'MIN',
  VALI_CHECK_TYPE_ONLY_NUMBER: 'NUM',
  VALI_CHECK_TYPE_SAME_VALUE: 'T&F',
  VALI_CHECK_TYPE_PER: 'PER',
};

export default {
  install(Vue: any) {
    Vue.config.globalProperties.$gMKConst = MKConst;
  },
};
