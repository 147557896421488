import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76979d50"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.pBtnInfo.imgPath && !_ctx.pBtnInfo.isCircle)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pBtnInfo.onClickFn && _ctx.pBtnInfo.onClickFn(...args))),
        class: _normalizeClass(["btnStyle commonSmallText fontBold", {
      MKDeepPointBG: _ctx.pBtnInfo.pointYn && _ctx.pBtnInfo.deepYn,
      MKPointBG: _ctx.pBtnInfo.pointYn && !_ctx.pBtnInfo.deepYn,
			MKGrayBG: _ctx.pBtnInfo.grayYn,
			MKPopGrayBG: _ctx.pBtnInfo.popGrayYn,
      noBorderBtn: _ctx.pBtnInfo.noBorder,
      imgBox: _ctx.pBtnInfo.imgPath,
    }])
      }, [
        _createElementVNode("img", {
          src: _ctx.pBtnInfo.imgPath,
          alt: _ctx.pBtnInfo.title
        }, null, 8, _hoisted_1)
      ], 2))
    : (_ctx.pBtnInfo.isCircle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.pBtnInfo.onClickFn && _ctx.pBtnInfo.onClickFn(...args))),
          class: _normalizeClass(["btnStyle commonSmallText fontBold", {
      MKDeepPointBG: _ctx.pBtnInfo.pointYn && _ctx.pBtnInfo.deepYn,
      MKPointBG: _ctx.pBtnInfo.pointYn && !_ctx.pBtnInfo.deepYn,
			MKGrayBG: _ctx.pBtnInfo.grayYn,
			MKPopGrayBG: _ctx.pBtnInfo.popGrayYn,
      noBorderBtn: _ctx.pBtnInfo.noBorder,
      circleBtn: _ctx.pBtnInfo.isCircle,
    }])
        }, [
          (_ctx.pBtnInfo.imgPath)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.pBtnInfo.imgPath,
                alt: _ctx.pBtnInfo.title
              }, null, 8, _hoisted_2))
            : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.pBtnInfo.btnText), 1))
        ], 2))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.pBtnInfo.onClickFn && _ctx.pBtnInfo.onClickFn(...args))),
          class: _normalizeClass(["btnStyle normalBtn commonSmallText fontBold", {
      MKDeepPointBG: _ctx.pBtnInfo.pointYn && _ctx.pBtnInfo.deepYn,
      MKPointBG: _ctx.pBtnInfo.pointYn && !_ctx.pBtnInfo.deepYn,
			MKGrayBG: _ctx.pBtnInfo.grayYn,
			MKPopGrayBG: _ctx.pBtnInfo.popGrayYn,
      noBorderBtn: _ctx.pBtnInfo.noBorder,
      lowHeightBtn: _ctx.pBtnInfo.noHighYn,
    }])
        }, _toDisplayString(_ctx.pBtnInfo.btnText), 3))
}