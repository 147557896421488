/* eslint-disable no-undef */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import router from '@/router';
import store from '@/store';
import { UserInfo, authData } from './MKGetSet';
import { HTTPfunctions } from '@/api';
let GISCallbackFunc = null as any;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
webviewBridge
  .registerCallbackFunc(webviewBridgelistener, 'jtpltMQCq8Y5lVQHepuIAQ==')
  .then((result: any) => {
    console.log('MKTeamplCore.ts', result);
  });
const isJsonString = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
export function setGISCallbackFunc (func: any) {
  GISCallbackFunc = func
}
async function webviewBridgelistener(data: any) {
  if (data && data.type != 'webpackWarnings') {
    console.log('native로부터 도착!!');
    console.log(data);
    if (data.type === 'userInfo') {
      let userInfo = data.data;
      // alert(JSON.stringify(userInfo))
      if (userInfo) {
        if (isJsonString(userInfo)) {
          userInfo = JSON.parse(userInfo);
        }
        console.log('userInfo', userInfo);
        const param = {} as UserInfo;
        let user = userInfo.userMap;
        if (!user) {
          if (userInfo.user) {
            user = userInfo.user
          }
        }
        param.userDispMtext = user.userDispMtext;
        param.encPhone = user.encPhone;
        param.phoneLast = user.phoneLast;
        param.soAccessToken = user.soAccessToken;
        param.soPicUrl = user.userImgUrl;
        param.soType = user.soType;
        param.userEmail = user.userEmail;
        param.tcAccessToken = user.tcAccessToken;
        param.fcmKey = user.fcmKey;
        param.deviceId = user.deviceId;
        param.isTablet = user.isTablet;
        // param.osName = user.osName;
        param.deviceModel = user.deviceModel;
        // param.apiLevel = user.apiLevel;

        const userResult: any = await HTTPfunctions.saveUser({
          user: param,
        });
        console.log('userResult', userResult);
        /* const userData = {}
        userData.tcAccessToken = userResult.data.user.tcAccessToken
        userData.partnerToken = userResult.data.user.partnerToken */
        if (userResult.data.result) {
          console.log(userResult);
          authData.setUserInfo(userResult.data.user)
          userResult.data.userMap = userResult.data.user
          authData.setUserAll(userResult.data)
          //this.$store.dispatch('MK_USER/AC_USER', userResult.data.user);
        } else {
          // localStorage.removeItem('user');
        }
        // store.dispatch('MK_USER/AC_USER', userInfo.userMap);
        router.replace({ name: 'main' });
      } else {
        router.replace({ name: 'policies' });
      }
    } else if (data.type === 'CheckUserPermission') {
      router.replace({ name: 'permissions' });
    } else if (data.type === 'requestUserPermission') {
      router.replace({ name: 'policies' });
    } else if (data.type === 'GPSInfo') {
      let stepCounts = data.data;
      if (isJsonString(stepCounts)) {
        stepCounts = JSON.parse(stepCounts);
      }
      if (GISCallbackFunc) {
        GISCallbackFunc(stepCounts)
      }
      console.log('steps', stepCounts);
    } else if (data.type === 'setFcmToken') {
      localStorage.setItem('fcmToken', data.data)
      localStorage.setItem('appYn', 'true')
      /* const param = {} as UserInfo;
          const user = authData.getUserInfo();
          param.userKey = user.userKey;
          param.fcm = user.tcAccessToken;
          const userResult: UserData | any = await this.$gHTTPfunctions.saveUser({
            user: param,
          }); */
    } else if (data.type === 'goBack') {
      const history = store.getters['MK_HISTORY/GE_HISTORY_STACK']
      if (history && history.length > 0) {
        const removeTarget = history[history.length -1]
        if (removeTarget.id.includes('router_')) {
          if (router.currentRoute.value.name === removeTarget.targetKind) {
            store.dispatch('MK_HISTORY/AC_DELETE_STACK', removeTarget.id)
            router.go(-1)
          }
        }
      }
    }  else if (data.type === 'pushmsg') {
      console.log(data)
      if (data.data) {
        const push = data.data
        const stat = authData.getNewPushStatus()
        authData.setNewPushStatus(!stat)
        if (push.creAppKey && push.clickYn) {
          const param = {appKey: push.creAppKey};
          const result = await HTTPfunctions.getAppList(param);
          const mGetAppList = result.data.app.content;
          console.log('this.mAppList', mGetAppList);
          if (push.clickYn && mGetAppList && mGetAppList.length > 0) {
            getClickedApp(mGetAppList[0], push)
          }
        }
      }
    }
  } 
}
function getClickedApp (app: any, data: any) {
  if (app) {
    const userInfo = authData.getUserAll();
    app.userInfo = userInfo
    if (data) {
      app.push = data
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore eslint-disable-next-line no-undef
      // eslint-disable-next-line no-undef
      iframeService.openMiniAppPop(app)
      return
  }

  debugger
  
}
export default {
  install(Vue: any) {
    webviewBridgelistener;
  },
};
