<template>
  <div class="MKPopBackShadow" @click="pPopInfo.closeFn" v-if="!pBackShadow"></div>
  <component :is="'layout'" class="MZPopLayoutWrap" :style="reponsiveSize">
    <header style="position:relative;">
      <h3 class="popTitle commonTitleText fontNavy">{{ pPopInfo.title }}</h3>
      <button class="backBtn MKPopCloseBtn " @click="pPopInfo.closeFn" style="position:absolute; top:50%; transform:translateY(-50%); ">
        <img src="@/assets/icons/newDesign/addIcon2.svg" style="transform:rotate(45deg); width:20px; height:20px;" alt="닫기" />
      </button>
    </header>

    <slot />

    <footer>
      <gBtn
        v-for="(btn, index) in pPopInfo.btnList"
        :key="index"
        :pBtnInfo="btn"
        :style="index > 0 ? 'margin-left: 10px;' : ''"
        style="min-width: 80px"
      />
    </footer>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * props 전달 예시
    {
      title: '팝업 제목',
      closeFn: () => {},
      size: { w: 600, h: 700 },
      btnList: [
        { btnText: '신고하기', onClickFn: () => {} },
        { btnText: '닫기', onClickFn: () => {}}
      ],
      isFull?: true,
      isConfirm?: true,
    }
 */

export default defineComponent({
  props: {
		pPopInfo: Object as any,
		pBackShadow: Boolean
  },
  data() {
    return {
      mScreenWidth: window.innerWidth,
      mScreenHeight: window.innerHeight,
    };
  },
  computed: {
    reponsiveSize() {
      console.log(this.pPopInfo)
      if (this.pPopInfo.isFull) {
        return {
          width: '100%',
          height: '100%',
        };
      } else if (this.pPopInfo.isConfirm) {
        return {
          width: (this.mScreenWidth / 10) * 9 + 'px',
          height: (this.mScreenHeight / 10) * 4.2 + 'px',
        };
      } else if (this.pPopInfo.size) {
        return {
          width: this.pPopInfo.size.w + 'px',
          height: this.pPopInfo.size.h + 'px',
        };
      } else {
        return {
          width: (this.mScreenWidth / 10) * 9 + 'px',
          height: (this.mScreenHeight / 10) * 7.5 + 'px',
        };
      }

    },
  },
  methods: {
    onResize() {
      this.mScreenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
});
</script>

<style scoped>
.MZPopLayoutWrap {
  border-radius: 1.5rem;
  z-index: 99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #f1f1f5; */
  background-color: #fff;

  border: 1px solid #ccc;
  box-shadow: 9px 9px 9px -9px #00000052;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.MKPopCloseBtn {
  display: flex;
  align-items: center;
}
header {
  position: relative;
  padding: 10px 0;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 7px 9px -9px #00000036; */
}
slot {
  width: 100%;
  height: calc(100% - 110px);
}
.backBtn {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}
.backBtn > img {
  width: 30px;
}
.popTitle {
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
}
footer {
  /* border-top: 1px solid #000; */
  height: 50px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px -7px 9px -9px #00000036; */
	margin-bottom:15px;
}
</style>
