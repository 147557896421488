<template>
  <div class="w100P flexCenter" style=" padding-top: 35px;justify-content: space-between; max-width:1290px; height: 85px;  background:#efefef; z-index: 9; position: absolute;top: 0; left: 50%; transform:translateX(-50%); padding: 10px 20px;">
      <div class="flexCenter w100P" style="padding-top: 35px; justify-content:space-between; ">
        <!-- <div class="MKAppLogo mRight-30" style="width: auto; height: 50px">
          <img src="@/assets/images/header/MKHeaderMainLogo.svg" alt="만끽 로고"/>
        </div> -->
        <p class="commonTitleText">{{pHeaderText}}</p>
        <div @click="goBack" class="MKAppGotoBack cursorP fr " style="width: 30px; height: 50px">
          <img src="@/assets/images/common/MKCloseIcon.svg" alt="닫기" class="w100P h100P"/>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pPopupId: {
      type: String
    },
    pHeaderText: {
      default: '만끽',
      type: String
    },
    pClosePop: Function
  },
  methods: {
    goBack () {
      if (this.pClosePop) {
        this.pClosePop()
      } else {
        if (window.parent) {
          let currentWindow = window;
          if (currentWindow.frameElement) {
            const iframeId = currentWindow.frameElement.id;
            if (iframeId) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore eslint-disable-next-line no-undef
              // eslint-disable-next-line no-undef
              iframeService.reqClosePopToParent(iframeId)
              return iframeId;
            } else {
              this.$router.go(-1)
            }
          } else {
            this.$router.go(-1)
          }
        } else {
          this.$router.go(-1)
        }
      }
      /*   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore eslint-disable-next-line no-undef
        // eslint-disable-next-line no-undef
      if (window.parent && (window.parent.location.origin !== window.origin)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore eslint-disable-next-line no-undef
        // eslint-disable-next-line no-undef
        iframeService.reqClosePopToParent()
      } else {
        this.$router.go(-1)
      } */
    }
    /* if (this.pPopupId) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore eslint-disable-next-line no-undef
        // eslint-disable-next-line no-undef
        const closeYn = tcSso.backTargetIframe()
        if (!closeYn) {
          this.$router.go(-1)
        } 
      } */
  },
  data () {
    return {
    }
  }

})
</script>

<style lang="sass" scoped>

</style>
