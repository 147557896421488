<template>
  <input
    :disabled="pInputInfo.disabled"
    :readonly="pInputInfo.readonly"
    class="MKCommonInput"
    @keyup.enter="enterEvent"
    v-if="pInputInfo.type === 'T'"
    type="text"
    :placeholder="pInputInfo.placeholder"
    v-model="mInputValue.value"
		:class="{backColor: pInputInfo.backColorYn}"
  />
  <input
    :disabled="pInputInfo.disabled"
    class="MKCommonInput"
    @keyup.enter="enterEvent"
    v-if="pInputInfo.type === 'P'"
    type="password"
    :placeholder="pInputInfo.placeholder"
    v-model="mInputValue.value"
  />
  <input
    :disabled="pInputInfo.disabled"
    class="MKCommonNumber"
    v-if="pInputInfo.type === 'N'"
    type="number"
    :placeholder="pInputInfo.placeholder"
    v-model="mInputValue.value"
  />
  <textarea
    class="MKCommonTextarea"
    @click="clickEvent"
    @keyup.enter="enterEvent"
    v-else-if="pInputInfo.type === 'TA'"
    v-model="mInputValue.value"
    :placeholder="pInputInfo.placeholder"
  />
  <select
		:disabled="pInputInfo.disabled"
    class="MKCommonSelect"
    v-model="mInputValue.value"
    v-else-if="pInputInfo.type === 'S'"
    @change="changeEvent"
  >
    <option
      v-for="(each, index) in pInputInfo.options"
      :value="each.value"
      :key="index"
    >
      {{ each.opt }}
    </option>
  </select>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * pInputInfo 전달 예시
    {
      type: 'T' | 'P' | 'N' | 'TA' | 'S',   => 전역변수 사용 요망
      value: string,
      options?: [
        { opt: '최신 순', value: 'recent' },
        { opt: '오래된 순', value: 'older' },
        { opt: '마감 순', value: 'imminent' },
      ],
      placeholder?: string,
      disabled?: boolean,
      readonly?: boolean
    }
 */

export default defineComponent({
  props: {
    pInputInfo: Object as any,
    pClickEvent: Function,
    pEnterEvent: Function,
    pChangeEvent:Function
  },
  data() {
    return {
      mInputValue: {} as any,
    };
  },
  methods: {
    clickEvent() {
      if (this.pClickEvent) {
        this.pClickEvent();
      }
    },
    changeEvent() {
      if (this.pChangeEvent) {
        this.pChangeEvent(this.mInputValue?.value);
      }
    },
    enterEvent() {
      if (this.pEnterEvent) {
        this.pEnterEvent();
      }
    },
  },
  watch: {
    pInputInfo: {
      immediate: true,
      handler(value) {
        if (!value) return;
        this.mInputValue = value;
      },
    },
  },
});
</script>

<style scoped>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MKCommonNumber {
  width: 100%;
  max-width: 300px;
  min-width: 130px;
  min-height: 35px;
  text-indent: 5px;
  border: 2px solid #d9d9d9;
  border-radius: 20px !important;
  padding: 0 5px;
}
.MKCommonInput {
  width: 100%;
  /* max-width: 300px; */
  min-width: 130px;
  min-height: 30px;
  text-indent: 5px;
  border: none;
	text-indent:10px;
  border-radius: 20px !important;
  padding: 10px 5px;
	box-sizing:border-box;
  border: 2px solid #d9d9d9;
}
.MKCommonTextarea {
	width:calc(100% - 10px);
  /* max-width: 300px; */
  min-width: 80px;
  height: auto;
  min-height: 20px;
  resize: none;
  border: none;
  border-radius: 20px !important;
  padding: 10px 5px;
  text-indent: 5px;
  border: 2px solid #d9d9d9;
}
.MKCommonSelect {
  border: 2px solid #d9d9d9;
  border-radius: 20px;
  height: 30px;
  padding: 5px;
	width:100%;
  font-size: 12px;
}
.MKCommonSelect:focus {
  outline: 0;
  /* border: 2px solid #3ab7ff; */
}
</style>
