import { createApp } from 'vue';
import App from './MKTotalMain.vue';
import router from './router';
import store from './store';
import commonTs from '@/utils/MKCommon';
import fileTs from '@/utils/MKFile';
import { api } from '@/utils/MKApiData';
import MKConst from '@/types/MKConst';
import httpFn from '@/api/index';
import axios from 'axios';
import getSetFunctions from '@/utils/MKGetSet';
import MkTpCoreSetting from '@/utils/MKTeamplCore';
/* import Vue3Lottie from 'vue3-lottie' */

/* common components */
import MKCommonInput from '@/components/common/MKCommonInput.vue';
import MKPopLayout from '@/components/common/popup/MKCommonPopLayout.vue';
import MKContentsObserver from '@/components/common/MKContentsObserver.vue';
import gBtn from '@/components/common/MKCommonBtn.vue';
import gPageHeader from '@/components/app/layout/MKAppPageHeader.vue';
import gScrollTopBtn from '@/components/app/common/MKAppGoTopQuickBar.vue';

const app = createApp(App);
app.component('gBtn', gBtn);
app.component('gPopup', MKPopLayout);
app.component('gInput', MKCommonInput);
app.component('gPageHeader', gPageHeader);
app.component('gMyObserver', MKContentsObserver);
app.component('gScrollTopBtn', gScrollTopBtn);
app.use(store).use(router).use(httpFn);
app.use(commonTs);
app.use(MKConst);
app.use(fileTs);
app.use(MkTpCoreSetting);
/* app.use(webviewBridge) */
app.use(getSetFunctions);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$API_DATA = api;

/* app.use(Vue3Lottie) */
app.mount('#app');
